<!-- 厂家管理 -->
<template>
	<div class="doctor_management" ref="tableCot">
		<el-row class="bread_crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/FactoryManagement' }">厂商管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/FactoryManagement' }">厂商列表</el-breadcrumb-item>
			</el-breadcrumb>
		</el-row>
		<div class="public_bg">
			<el-row>
				<el-col :span="24">
					<div class="grid-content">
						<template>
							<div class="form-style">
								<el-form ref="formInline" :inline="true" :model="formInline" class="demo-form-inline">
									<el-form-item label="厂商名称:" prop="manufacturerName">
										<el-input clearable v-model="formInline.manufacturerName" placeholder="请输入"></el-input>
									</el-form-item>
									<el-form-item>
										<el-button type="primary" @click="querys">搜索</el-button>
										<el-button @click="resetForm('formInline')">重置</el-button>
										<el-button type="primary" icon="el-icon-plus" @click="NewAdd('新增',{})">新增</el-button>
									</el-form-item>
								</el-form>
							</div>
							<div class="tableMargin">
								<el-table :height="Height" stripe ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}" :data="ManufacturerPageData"
								 style="width: 100%">
									<el-table-column label="序号" type="index" width="50" align="left">
									</el-table-column>
									<el-table-column align="left" prop="manufacturerName" label="厂商名称">
									</el-table-column>
									<el-table-column label="操作" align="left">
										<template slot-scope="scope">
											<el-button type="text" :class="scope.row.status!=='200000' ? 'infos' :'primarys'" @click="NewAdd('编辑',scope.row)">编辑</el-button>
											<el-button type="text" @click="NewProduct(scope.row.manufacturerId)">新增产品</el-button>
											<el-button type="text" :class="scope.row.status=='200000' ? 'dangers' :'primarys'" @click="NewAdd('状态',scope.row)">{{scope.row.status=='200000' ? '停用' :'启用'}}</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<v-paging v-on:childPage="pages" :total=total></v-paging>
						</template>
					</div>
				</el-col>
			</el-row>
			<!-- 编辑新增模态框 -->
			<el-dialog :title="title" :visible.sync="DialogVisible" width="40%">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="厂商名称:" prop="manufacturerName">
						<el-input clearable placeholder="请输入" v-model="ruleForm.manufacturerName"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="DialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="DialogVisibleSave">确 认</el-button>
				</span>
			</el-dialog>
			<!-- 新增产品模态框 -->
			<el-dialog title="产品管理" :visible.sync="ProductDialogVisible" width="50%">
				<el-form :model="ProductruleForm" :inline="true" :rules="Productrules" ref="ProductruleForm" label-width="80px"
				 class="demo-ruleForm">
					<el-form-item label="产品名称:" prop="productNames">
						<el-input v-model="ProductruleForm.productNames" placeholder="请输入" clearable @input="change($event)"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="Productquerys">查询</el-button>
						<el-button type="primary" icon="el-icon-plus" @click="ProductNewAdd">新增</el-button>
					</el-form-item>
				</el-form>
				<div class="tableMargin">
					<el-table :row-class-name="tableRowClassName" ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}"
					 :data="ProductPageData" style="width: 100%">
						<el-table-column align="left" prop="productName" label="产品名称">
							<template slot-scope="scope">
								<span v-if="!scope.row.flag">{{scope.row.productName}}</span>
								<el-form v-else>
									<el-form-item>
										<el-input class="input_no" clearable v-model="scope.row.productName" placeholder="请输入" />
									</el-form-item>
								</el-form>
							</template>
						</el-table-column>
						<el-table-column align="left" prop="productModel" label="产品型号">
							<template slot-scope="scope">
								<span v-if="!scope.row.flag">{{scope.row.productModel}}</span>
								<el-form v-else>
									<el-form-item>
										<el-input class="input_no" clearable v-model="scope.row.productModel" placeholder="请输入" />
									</el-form-item>
								</el-form>
							</template>

						</el-table-column>

						<el-table-column align="left" prop="productDescription" label="产品描述">
							<template slot-scope="scope">
								<span v-if="!scope.row.flag">{{scope.row.productDescription}}</span>
								<el-form v-else>
									<el-form-item>
										<el-input class="input_no" clearable v-model="scope.row.productDescription" placeholder="请输入" />
									</el-form-item>
								</el-form>
							</template>
						</el-table-column>
						<el-table-column align="left" label="操作" width="200px">
							<template slot-scope="scope">
								<template v-if="!scope.row.flag">
									<el-button type="text" :class="scope.row.status!==200000 ? 'dangers' :'primarys'" @click="productEditAdd(scope.row)">{{scope.row.status!==200000 ? '停用' :'启用'}}</el-button>
								</template>
								<template v-else>
									<el-button type="text" @click="cancel(scope.row)">取消</el-button>
									<el-button type="text" @click="productsave(scope.row)">保存</el-button>
								</template>
							</template>
						</el-table-column>
					</el-table>
					<v-paging v-on:childPage="productPages" :total=productTotal></v-paging>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="ProductDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="ProductDialogVisible = false">确 认</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			tableRowClassName() {
				return 'style-row';
			},
			//解决输入框无法输入的问题
			change(e) {
				this.$forceUpdate();
			},
			productPages() {
				this.QueryProductQueryPage()
			},
			//产品分页接口
			async QueryProductQueryPage(current = 1, size = 10) {
				let info = {
					current: current,
					size: size,
					productName: this.ProductruleForm.productNames,
					manufacturerId: this.manufacturerId,
					menuId: this.menuCode,
				}
				let res = await this.$Http.ProductQueryPageInfo(info)
				if (res.code == '200') {
					this.ProductPageData = res.data.data;
					this.productTotal = res.data.total;
				} else {
					this.ProductPageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//新增产品接口
			async QueryProductInsertQueryPage(item) {
				let info = {
					productName: item.productName,
					productModel: item.productModel,
					productDescription: item.productDescription,
					manufacturerId: this.manufacturerId
				}
				let res = await this.$Http.ProductInsertByInfo(info)
				if (res.code == '200') {
					this.QueryProductQueryPage();
				} else {
					this.$message.error(res.message);
				}
			},
			//修改产品接口
			async QueryProductUpdateQueryPage(item) {
				console.log(item, "item")
				let info = {
					manufacturerProductId: item.manufacturerProductId,
					productName: item.productName,
					productModel: item.productModel,
					productDescription: item.productDescription,
					manufacturerId: this.manufacturerId,
					status: this.status
				}
				let res = await this.$Http.ProductUpdateByInfo(info)
				if (res.code == '200') {
					this.QueryProductQueryPage();
				} else {
					this.$message.error(res.message);
				}
			},
			//编辑产品
			productEditAdd(item) {
				item.status == "200000" ? this.status = "200001" : this.status = "200000";
				this.QueryProductUpdateQueryPage(item);
			},
			//新增产品保存
			productsave(item) {
				this.QueryProductInsertQueryPage(item);
			},
			//取消
			cancel(item) {
				var index = this.ProductPageData.indexOf(item)
				if (index !== -1) {
					this.ProductPageData.splice(index, 1)
				}
			},
			//产品搜索
			Productquerys() {
				this.QueryProductQueryPage();
			},
			//产品新增
			ProductNewAdd() {
				this.ProductPageData.push({
					productName: '',
					productModel: '',
					productDescription: "",
					flag: "1"
				})
			},
			NewProduct(manufacturerId) {
				this.manufacturerId = manufacturerId;
				this.ProductDialogVisible = true;
				this.$nextTick(() => {
					this.$refs["ProductruleForm"].resetFields();
				})
				//产品分页
				this.QueryProductQueryPage();
			},
			//分页接口
			async QueryManufacturerQueryPage(current = 1, size = 10) {
				let info = {
					current: current,
					size: size,
					manufacturerName: this.formInline.manufacturerName,
					menuId: this.menuCode,
				}
				let res = await this.$Http.ManufacturerQueryPageInfo(info)
				if (res.code == '200') {
					this.ManufacturerPageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.ManufacturerPageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//新增接口
			async QueryManufacturerInsertBy() {
				let info = {
					manufacturerName: this.ruleForm.manufacturerName
				}
				let res = await this.$Http.ManufacturerInsertByInfo(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.QueryManufacturerQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//修改接口
			async QueryManufacturerUpdateBy() {
				let info = {
					manufacturerName: this.ruleForm.manufacturerName,
					manufacturerId: this.ruleForm.manufacturerId,
					status: this.status
				}
				let res = await this.$Http.ManufacturerUpdateByInfo(info);
				this.DialogVisible = false;
				if (res.code == '200') {
					this.QueryManufacturerQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//保存
			DialogVisibleSave() {
				this.title == "新增厂商" ? this.QueryManufacturerInsertBy() : this.QueryManufacturerUpdateBy();
			},
			//分页组件
			pages(current, size) {
				this.QueryManufacturerQueryPage(current, size);
			},
			//编辑新增
			NewAdd(val, item) {
				this.ruleForm = JSON.parse(JSON.stringify(item));
				this.ruleForm.Provinces = [item.province, item.city];
				if (val == "状态") {
					item.status == "200000" ? this.status = "200001" : this.status = "200000";
					this.QueryManufacturerUpdateBy();
				} else {
					if (item.status == "200001") {
						this.$message.error("已停用,不可编辑");
						return false;
					} else {
						this.DialogVisible = true;
						this.title = `${val}厂商`;
						this.status = item.status;
					}

				}
			},
			// 搜索
			querys() {
				this.QueryManufacturerQueryPage();
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		data() {
			return {
				Height: document.documentElement.clientHeight - 280,
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
				newStatus: false,
				//产品数据
				ProductPageData: [],
				ProductruleForm: {},
				Productrules: {},
				ProductDialogVisible: false,
				ruleForm: {},
				rules: {},
				DialogVisible: false,
				//title
				title: "",
				//form表单查询
				formInline: {},
				//table列表
				ManufacturerPageData: [],
				//总条数
				total: 0,
				productTotal: 0,
				rules: {
					manufacturerName: [{
						required: true,
						message: "请填写厂家名称",
						trigger: "blur"
					}, ]
				}
			}
		},
		created() {

		},
		mounted() {
			//分页
			this.QueryManufacturerQueryPage();


		}
	}
</script>
<style scoped>
</style>
<style>
    .style-row td {
		border-top: 10px solid #fff;
	}
	.el-table .style-row {
		background: #FAFAFA;
		margin-top: 10px !important;
	}

	.style-row {
		margin-top: 10px !important;
	}

	.input_no input {
		border: none;
		background: #FAFAFA;
	}

	.el-table .cell {
		height: 40px;
		line-height: 40px;
	}
</style>
